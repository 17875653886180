<template>
  <div>
    <v-data-table
      v-model="selected"
      :show-select="totaleFornitori > 0"
      item-key="fornitore"
      :headers="headers"
      :items="fornitoriSorted"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      @click:row="clickRow"
      @dblclick:row="dblclickRow"

      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Fornitori e Servizi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="onNuovoFornitore">Nuovo Fornitore</v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confermi cancellazione fornitore?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">SI</v-btn>
              <v-btn color="blue darken-1" text @click="closeDelete">NO</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogFornitore" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field v-model="editedItem.fornitore" label="Nome fornitore" :readonly="editedIndex !== -1"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-select :items="tipiFornitore" label="Tipo" v-model="editedItem.tipo" item-value="item" item-text="item" hide-details></v-select> 
                  </v-col>           
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Annulla</v-btn>
              <v-btn color="blue darken-1" text @click="save">Salva</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>        
      </v-toolbar>
      <filtri @ricerca="onRicerca"/>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)" title="Modifica il fornitore">mdi-pencil</v-icon>
    </template>    
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="fornitoriSelected">
        <v-tabs dense v-model="tab[item.codice]">
          <v-tab class="fornitoriSelected">Servizi{{item.name}}</v-tab>
          <v-tab class="fornitoriSelected">Alias</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab[item.codice]">
          <v-tab-item>
            <v-row no-gutters>
              <v-col cols="6">
                <servizi :fornitore="item.fornitore" :items="item.servizi" :servizi="elencoServizi" :triggerUpdate="triggerUpdate" @update="onUpdateServizio"/>
              </v-col>
              <v-col cols="6"></v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row no-gutters>
              <v-col cols="6">
                <alias :fornitore="item.fornitore" :items="item.alias"  :triggerUpdate="triggerUpdate" @update="onUpdateAlias" @delete="onDeleteAlias"/>
              </v-col>
              <v-col cols="6"></v-col>
            </v-row>            
          </v-tab-item>
         </v-tabs-items>
      </td>
    </template>    
    </v-data-table>
  </div>
</template>

<script>
  import _ from 'lodash'
  import Filtri from './filtri'

  import Alias from './elementi/alias'
  import Servizi from './elementi/servizi'
  import selfapi from '@/services/selfapi.js'

/*   import fornitori from '@/services/fornitoriService.js'
  import licenze from '@/services/licenzeService.js'
  import impostazioni from '@/services/impostazioniService.js' */
  
  export default {
    components: {
      Alias,
      Servizi,
      Filtri
    },
    data () {
      return {
        totaleFornitori: 0,
        fornitori: [],
        selected: [],
        loading: true,
        options: {},
        headers: [
         /*  { text: 'Codice', value: 'codice', align: 'start' }, */
          { text: 'Fornitore', value: 'fornitore' },
/*           { text: 'Postazioni', value: 'numeroPostazioni' },
          { text: 'Usate', value: 'numeroPostazioniUsate' },
          { text: 'Data scadenza', value: 'dataScadenza' },  */
          { text: 'Tipo', value: 'tipo' }, 
/*           { text: 'Base DB', value: 'baseDb' },
          { text: 'Data scadenza', value: 'dataScadenza' },          
          { text: 'Attivo', value: 'attivo' }, */
          { text: 'Actions', value: 'actions', sortable: false, width: '10%' },
          { text: '', value: 'data-table-expand' }
        ],
        expanded: [],
        singleExpand: false,
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: {
          fornitore: '',
          tipo: 'STANDARD',
          servizi: [],
          alias: []
        },
        filtri: null,
        tab: {},
        triggerUpdate: 1,
        serviziValidati: [],
        elencoServizi: [],
        totaleServizi: 0,
        dialogFornitore: false,
        tipiFornitore: ['PREMIUM', 'STANDARD']
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo Fornitore' : 'Modifica Fornitore'
      },
      fornitoriSorted() {
        return _.sortBy(this.triggerUpdate && this.fornitori, ['fornitore'])
      }
    },
    watch: {
      options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },
    created() {
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    async mounted () {
      await this.getServiziValidati()
      await this.getDataFromApi()
    },
    methods: {
      async onUpdateServizio(codiceFornitore, item) {
        let fornitore = this.fornitori.find(x => x.fornitore === codiceFornitore)
        if (fornitore) {
          let idx = fornitore.servizi.findIndex(x => x.servizio === item.servizio)
          if (idx > -1) {
            fornitore.servizi[idx] = item
          } else {
            fornitore.servizi.push(item)
          }
        }
        let daSalvare = _.pick(fornitore, ['fornitore', 'servizi'])
        daSalvare.servizi = daSalvare.servizi.map(x => {
          return {
            nome: x.servizio,
            stato: x.stato
          }
        })
        await selfapi.aggiornaServiziValidati(daSalvare)
        this.triggerUpdate++
      },
      async onUpdateAlias(codiceFornitore, alias) {
        let fornitore = this.fornitori.find(x => x.fornitore === codiceFornitore)
        if (fornitore) {
          let idx = fornitore.alias.findIndex(x => x === alias.originale)
          if (idx > -1) {
            fornitore.alias[idx] = alias.codice
          } else {
            fornitore.alias.push(alias.codice)
          }
        }
        let daSalvare = _.omit(fornitore, ['servizi'])

        await selfapi.aggiornaFornitore(daSalvare)
        this.triggerUpdate++
      },
      async onDeleteAlias(codiceFornitore, alias) {
        let fornitore = this.fornitori.find(x => x.fornitore === codiceFornitore)
        if (fornitore) {
          let idx = fornitore.alias.findIndex(x => x === alias.originale)
          if (idx > -1) {
            fornitore.alias.splice(idx, 1)
          }
        }
        let daSalvare = _.omit(fornitore, ['servizi'])
        await selfapi.aggiornaFornitore(daSalvare)

        this.triggerUpdate++        
      },
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getServiziValidati() {
        try {
          this.loading = true
          let serviziRaw = await selfapi.getServiziValidati()
          this.totaleServizi = serviziRaw.length
          this.serviziValidati = serviziRaw

          this.elencoServizi = _.uniq(_.flatten(serviziRaw.map(x => x.servizi)).map(x => x.nome)).sort()
        } catch(err) {
          this.serviziValidati = []
          this.totaleServizi = 0
        } finally {
          this.loading = false
        }
      },
      async getDataFromApi () {
        try {
          this.loading = true
          let fornitoriRaw = await selfapi.getElencoFornitori()
          if (this.filtri.testo) {
            const ft = this.filtri.testo.toLowerCase()
            fornitoriRaw = fornitoriRaw.filter(x => x.fornitore.toLowerCase().includes(ft))
          }
          if (this.filtri.premium) {
            fornitoriRaw = fornitoriRaw.filter(x => x.tipo === 'PREMIUM')
          }          

          this.totaleFornitori = fornitoriRaw.length
          this.fornitori = fornitoriRaw
          for (let f of this.fornitori) {
            const sv = this.serviziValidati.find(s => s.fornitore.toLowerCase() === f.fornitore.toLowerCase())
            if (!sv || !sv.servizi) {
              f.servizi = []
              continue
            }
            f.servizi = sv.servizi.map(x => {
              return {
                fornitore: sv.fornitore,
                servizio: x.nome,
                validato: x.validato || x.stato === 'VALIDATO' ? true : false,
                stato: x.stato
              }
            })
          }
        } catch(err) {
          this.fornitori = []
          this.totaleFornitori = 0
        } finally {
          this.loading = false
        }
      },
      onNuovoFornitore() {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialogFornitore = true
      },
      editItem (item) {
        this.editedIndex = this.fornitori.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogFornitore = true
/*         this.editedIndex = this.fornitori.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('fornitori/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true) */
      },
      duplicateItem(item) {
/*         this.editedIndex = -1
        this.editedItem = Object.assign({}, item)
        this.editedItem._id = ''
        delete this.editedItem._rev
        this.$store.commit('fornitori/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true) */
      },
      deleteItem (item) {
/*         this.editedIndex = this.fornitori.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true */
      },

      async deleteItemConfirm () {
/*         this.fornitori.splice(this.editedIndex, 1)
        if (!await fornitori.cancellaFornitore(this.editedItem)) {
          this.dialogErrore = true
        }
        this.closeDelete() */
      },
      close () {
        this.dialogFornitore = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },      
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
        var fornitore
        if (this.editedIndex > -1) {
          fornitore = _.pick(this.editedItem, ['fornitore', 'tipo', 'alias'])
        } else {
          if (this.editedItem.fornitore.length > 0) {
            fornitore = _.pick(this.editedItem, ['fornitore', 'tipo', 'alias'])
          }
        }
        if (fornitore) {
          console.log(fornitore)
          await selfapi.aggiornaFornitore(fornitore)
        }
        this.close()
        await this.getDataFromApi()
      },
      erroreConfirm() {
        this.dialogErrore = false
      },
      clickRow(item, options) {

      },
      dblclickRow(event, options) {
/*         const item = options.item
        this.editedIndex = this.fornitori.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('fornitori/SET_CLIENTE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', true)
        this.$store.commit('SET_RIGHT_DRAWER', true) */
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.fornitoriSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }

</style>
