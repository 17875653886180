<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="3">Filtri</v-col>
            <v-col cols="8" class="text--secondary">
              <span  v-if="open" key="0">Imposta i filtri di visualizzazione</span>
              <span v-else key="1">
                {{ filtro.testo }}
              </span>
            </v-col>
            <v-col cols="1" v-if="open"><v-btn @click.stop="onFiltra">Ricerca</v-btn></v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center" dense>
          <v-col md="5">
            <v-text-field v-model="filtro.testo" dense outlined label="Fornitore" clearable @keydown.enter="onFiltra" hide-details @click:clear="onClear"></v-text-field>
          </v-col>
<!--           <v-col md="2">
            <v-switch v-model="filtro.soloAttivi" dense inset label="Solo attivi" @change="onFiltra"></v-switch>
          </v-col> -->
          <v-col md="2">
            <v-switch v-model="filtro.premium" dense inset label="Solo premium" @change="onFiltra"></v-switch>
          </v-col>       
          <v-col md="7"></v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  // import configurazioni from '@/services/configurazioniService.js'
  export default {
    data: () => ({
      filtro: {
        testo: '',
        soloAttivi: false,
        premium: false
      }
    }),
    async mounted() {
      this.$emit('ricerca', this.filtro)
    },
    computed: {
    },
    methods: {
      onFiltra() {
        this.$emit('ricerca', this.filtro)
      },
      async onClear() {
        await this.$nextTick()
        this.onFiltra()
      }
    }
  }
</script>
