<template>
  <v-data-table
    :headers="headers"
    :items="sortedItems"
    sort-by="tipo"
    class="elevation-1"
    dense
    :items-per-page="50"
  >
  <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialogNuovo" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark small class="mb-2" v-bind="attrs" v-on="on" dense>
              Nuovo servizio
            </v-btn>
            <v-spacer></v-spacer>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-select v-model="editedItem.servizio" label="Servizio" :items="serviziRimasti" item-text="item" item-value="item"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.stato" label="Stato" :items="stati" item-text="codice" item-value="descrizione"></v-select></v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogModifica" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4"><v-select v-model="editedItem.stato" label="Stato servizio" :items="stati" item-text="codice" item-value="descrizione"></v-select></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>        
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Sei sicuro di voler cancellare questo servizio ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.attivo`]="{ item }">
      <v-simple-checkbox v-model="item.attivo" disabled />
    </template>
    <template v-slot:[`item.stato`]="{ item }">
        <span :class="coloreBack(item.stato)">{{item.stato}}</span>
      </template>    
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
    </template>
    <template v-slot:no-data>
      Nessun servizio definito
    </template>
    <template v-slot:expanded-item="{ }">
    </template>
  </v-data-table>
</template>

<script>
  /* eslint-disable */
  // import { validazioni } from '@/mixins/validazioni.js'
  
  export default {
    components: {
    },
    props: {
      fornitore: String,
      items: Array,
      servizi: Array,
      triggerUpdate: Number
    },
    data: () => ({
      dialogNuovo: false,
      dialogModifica: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Servizio',
          align: 'start',
          sortable: false,
          value: 'servizio',
        },
        // { text: 'Tipo', value: 'tipo' },
        { text: 'Stato', value: 'stato' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      updateTrigger: 0,
      editedIndex: -1,
      editedItem: {
        servizio: '',
       // tipo: '',
        stato: ''
      },
      defaultItem: {
        servizio: '',
        // tipo: '',
        stato: ''
      },
      expanded: [],
      singleExpand: false,
      stati: ["VALIDATO", "NON VALIDATO", "NON ASSEVERABILE", "NON CANDIDATO"],
      serviziRimasti: []
    }),
    watch: {
      dialogNuovo(value) {
        if (value) {
          this.serviziRimasti = _.difference(this.servizi, this.items ? this.items.map(x => x.servizio) : [])
        }
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo servizio' : 'Modifica servizio'
      },
      sortedItems() {
        const items = this.triggerUpdate ? this.items || [] : []
        const noSito = items.filter(x => !x.servizio.startsWith('Sito Internet'))
        const sito = items.filter(x => x.servizio.startsWith('Sito Internet'))
        return sito.concat(_.sortBy(noSito, ['servizio']))        
      }
    },
    methods: {
      coloreBack(stato) {
        switch (stato) {
          case 'VALIDATO':
            return 'green lighten-4  green--text text--darken-1'
          case 'NON VALIDATO':
            return 'amber lighten-4 deep-orange--text text--darken-2'
          case 'NON ASSEVERABILE':
            return 'red lighten-4 red--text text--darken-4'
          default:
            return ''
        }
      },
      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogModifica = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
         this.$emit('delete', this.codiceCliente, this.editedItem)
        this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialogNuovo = false
        this.dialogModifica = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        this.editedItem.fornitore = this.editedItem.fornitore || this.fornitore
        this.$emit('update', this.fornitore, this.editedItem)
        this.close()
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.utenteSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #119432 !important;
  }
</style>
