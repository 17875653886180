'use strict'
import store from '../store'

require('dotenv').config();

const service = process.env.VUE_APP_SERVICE_SELFAPI;
const apikey = process.env.VUE_APP_APIKEY_SELFAPI;
const xApikey = process.env.VUE_APP_XAPIKEY_GATEWAY || ''

async function messaggiServizio() {
  try {
  const response = await fetch(`${service}/assevera/messaggi?token=${apikey}`, {
    headers: {
      Authorization: 'Bearer ' + request.bearerToken,
      'Content-Type': 'application/json',
      'x-api-key': xApikey
    }
    })
    return await response.json()  
  } catch (error) {
    return error
  } 
}

async function entiAsseverabili(asseverazione, asseverabili) {
  try {
    const token = await store.getters['utenti/token']
    const response = await fetch(`${service}/assevera/enti/asseverabili?asseverazione=${asseverazione ? 'true' : 'false'}&asseverabili=${asseverabili ? 'true' : 'false'}&list=true&token=${apikey}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      })      
    })
    return await response.json()    
  } catch (error) {
    console.log(error.message)
    return error
  } 
}

async function getAllCriteri() {
  try {
    const token = await store.getters['utenti/token']
    const response = await fetch(`${service}/assevera/comune/all?token=${apikey}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      })      
    })
    return await response.json()    
  } catch (error) {
    console.log(error.message)
    return error
  } 
}

async function getInfoReportCrawler(ente, contenuto) {
  try {
  const response = await fetch(`${service}/assevera/enti/report/info?ente=${ente}${contenuto ? '&contenuto=true' : '' }&token=${apikey}`, {
    headers: new Headers({
      'x-api-key': xApikey,
      'Content-Type': 'application/json'
    })
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function getReportCrawler(ente) {
  try {
  const response = await fetch(`${service}/assevera/enti/report?ente=${ente}&token=${apikey}`, {
    headers: new Headers({
      'x-api-key': xApikey,
      'Content-Type': 'application/json'
    })
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function getStatisticaEnti() {
  try {
  const response = await fetch(`${service}/assevera/enti/statistica?token=${apikey}`, {
    headers: new Headers({
      'x-api-key': xApikey,
      'Content-Type': 'application/json'
    })
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function getStatisticaTask(periodi) {
  const data = { periodi }
  try {
    const response = await fetch(`${service}/assevera/task/statistica?token=${apikey}`, {
      method: 'POST',
      headers: new Headers({
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function userLogin(username, password, token) {
  const data = {
    sfUsername: username,
    sfPassword: password,
    sfToken: token,
    emailArea: ''
  }
  try {
    const response = await fetch(`${service}/assevera/login?token=${apikey}&lea=true`, {
      method: 'POST',
      headers: new Headers({
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function refreshToken(bearerToken) {
  try {
    const data = {
      refreshToken: bearerToken
    }
    const response = await fetch(`${service}/assevera/refresh?token=${apikey}&lea=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': xApikey
      },
      body: JSON.stringify(data)          
    })
    let info = await response.json()
    return info
  }
  catch (error) {
    return error
  } 
}

async function getElencoFornitori() {
  try {
  const response = await fetch(`${service}/assevera/fornitori?token=${apikey}`, {
    headers: new Headers({
      'x-api-key': xApikey,
      'Content-Type': 'application/json'
    })
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function getServiziValidati() {
  try {
    const response = await fetch(`${service}/assevera/servizi?token=${apikey}`, {
      headers: new Headers({
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      })
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function aggiornaServiziValidati(fornitore) {
  try {
    const response = await fetch(`${service}/assevera/fornitori/servizi?token=${apikey}&lea=true`, {
      method: 'POST',
      headers: new Headers({
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(fornitore)
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

async function aggiornaFornitore(fornitore) {
  try {
    const response = await fetch(`${service}/assevera/fornitori/fornitore?token=${apikey}&lea=true`, {
      method: 'POST',
      headers: new Headers({
        'x-api-key': xApikey,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(fornitore)
    })
    return await response.json()    
  } catch (error) {
    return error
  } 
}

export default {
  messaggiServizio,
  entiAsseverabili,
  getInfoReportCrawler,
  getReportCrawler,
  getStatisticaEnti,
  getStatisticaTask,
  getAllCriteri,
  userLogin,
  refreshToken,
  getElencoFornitori,
  getServiziValidati,
  aggiornaServiziValidati,
  aggiornaFornitore
}